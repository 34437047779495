import { AllLayersPrecedence } from '@/layers/constant'
import {
  Metadata,
  Audience,
  Auth,
  Media,
  Proximity,
  Export,
  Package,
  Poi,
  Basemap,
} from '@workspaces/types'

export const METADATA: Metadata.AppMetadata = {
  app_title: 'Clear Channel International',
  logo: 'logo-radarview-big.svg',
  flavour: 'EU',
  flavour_testing: 'Test_EU',
  app_config: {
    carto_client_id: {
      dev: 'oR2eLxeCTSB2lrGvqbiGvfYFY7tiergz',
      staging: 'CzpbAjmBCMqUpPrrwGQrO7798QYLubn8',
      prod: 'QCTEFxQuD1MZhyVQr2ghWLJ8QkF5SLCo',
    },
    carto_organization: 'org_6Qf9JYivDnTfmkRs',
    carto_organization_required_for_testing: false,
    front_url: [
      'https://radarview-c3.carto.solutions/.*',
      'https://radarview-c3-dev.carto.solutions/.*',
      'https://radarview-c3-staging.carto.solutions/.*',
      'https://radarview-dev.carto.solutions/.*',
      'https://radarview-staging.carto.solutions/.*',
      'https://radarview.carto.solutions/.*',
    ],
    firebase: {
      dev: {
        apiKey: 'AIzaSyCMndU-aYphA-SlbckfpU2DAall_k2dl_M',
        authDomain: 'carto-ps-radarview-c3-eu-dev.firebaseapp.com',
        projectId: 'carto-ps-radarview-c3-eu-dev',
        storageBucket: 'carto-ps-radarview-c3-eu-dev.appspot.com',
        messagingSenderId: '30208993596',
        appId: '1:30208993596:web:accc266a1f2ed746335639',
      },
      staging: {
        apiKey: 'AIzaSyDg8gqkHg8OdORleY3VdJLVx9zXIcLVoAM',
        authDomain: 'carto-ps-radarview-c3-eu-stg.firebaseapp.com',
        projectId: 'carto-ps-radarview-c3-eu-stg',
        storageBucket: 'carto-ps-radarview-c3-eu-stg.appspot.com',
        messagingSenderId: '509881586348',
        appId: '1:509881586348:web:d47b916bdd65a108860509',
      },
      prod: {
        apiKey: 'AIzaSyDQvKj5kHYAeaePBos3B8lHN91JY0yGVm0',
        authDomain: 'carto-ps-radarview-c3-eu-pro.firebaseapp.com',
        projectId: 'carto-ps-radarview-c3-eu-pro',
        storageBucket: 'carto-ps-radarview-c3-eu-pro.appspot.com',
        messagingSenderId: '251909161860',
        appId: '1:251909161860:web:fe0171b8fd6812a8ef13d1',
      },
    },
    remote_functions: {
      firebase_token: {
        dev: 'https://europe-west1-carto-ps-radarview-c3-eu-dev.cloudfunctions.net/default-function',
        staging:
          'https://europe-west1-carto-ps-radarview-c3-eu-stg.cloudfunctions.net/default-function',
        prod: 'https://europe-west1-carto-ps-radarview-c3-eu-pro.cloudfunctions.net/default-function',
        local:
          'http://127.0.0.1:5001/carto-ps-radarview-c3-eu-dev/us-central1/firebasToken',
      },
      audit: {
        dev: 'https://europe-west1-carto-ps-radarview-c3-eu-dev.cloudfunctions.net/reports',
        staging:
          'https://europe-west1-carto-ps-radarview-c3-eu-stg.cloudfunctions.net/reports',
        prod: 'https://europe-west1-carto-ps-radarview-c3-eu-pro.cloudfunctions.net/reports',
      },
      big_query_custom_POIS: {
        dev: 'https://europe-west1-carto-ps-radarview-c3-eu-dev.cloudfunctions.net/custom-pois',
        staging:
          'https://europe-west1-carto-ps-radarview-c3-eu-stg.cloudfunctions.net/custom-pois',
        prod: 'https://europe-west1-carto-ps-radarview-c3-eu-pro.cloudfunctions.net/custom-pois',
      },
      aida: {
        dev: 'https://europe-west1-carto-ps-radarview-c3-eu-dev.cloudfunctions.net/aida',
        staging:
          'https://europe-west1-carto-ps-radarview-c3-eu-stg.cloudfunctions.net/aida',
        prod: 'https://europe-west1-carto-ps-radarview-c3-eu-pro.cloudfunctions.net/aida',
      },
      share_plan: {
        dev: 'https://europe-west1-carto-ps-radarview-c3-eu-dev.cloudfunctions.net/plan',
        staging:
          'https://europe-west1-carto-ps-radarview-c3-eu-stg.cloudfunctions.net/plan',
        prod: 'https://europe-west1-carto-ps-radarview-c3-eu-pro.cloudfunctions.net/plan',
        local:
          'http://127.0.0.1:5001/carto-ps-radarview-c3-eu-dev/us-central1/plan',
      },
      isochrones: {
        dev: 'https://europe-west1-carto-ps-radarview-c3-eu-dev.cloudfunctions.net/isochrones',
        staging:
          'https://europe-west1-carto-ps-radarview-c3-eu-stg.cloudfunctions.net/isochrones',
        prod: 'https://europe-west1-carto-ps-radarview-c3-eu-pro.cloudfunctions.net/isochrones',
      },
      custom_geoboundaries: {
        dev: 'https://europe-west1-carto-ps-radarview-c3-eu-dev.cloudfunctions.net/custom-geoboundaries',
        staging:
          'https://europe-west1-carto-ps-radarview-c3-eu-stg.cloudfunctions.net/custom-geoboundaries',
        prod: 'https://europe-west1-carto-ps-radarview-c3-eu-pro.cloudfunctions.net/custom-geoboundaries',
      },
      optimize_distribution: {
        dev: 'https://europe-west1-carto-ps-radarview-c3-eu-dev.cloudfunctions.net/generic/insert-asset-ids',
        staging:
          'https://europe-west1-carto-ps-radarview-c3-eu-stg.cloudfunctions.net/generic/insert-asset-ids',
        prod: 'https://europe-west1-carto-ps-radarview-c3-eu-pro.cloudfunctions.net/generic/insert-asset-ids',
      },
      geocoding: {
        dev: 'https://europe-west1-carto-ps-radarview-c3-eu-dev.cloudfunctions.net/generic/geocoding',
        staging:
          'https://europe-west1-carto-ps-radarview-c3-eu-stg.cloudfunctions.net/generic/geocoding',
        prod: 'https://europe-west1-carto-ps-radarview-c3-eu-pro.cloudfunctions.net/generic/geocoding',
      },
    },
    basemaps: {
      enable: false,
      default: Basemap.Basemap.OpenStreetMaps,
      options: [
        { id: Basemap.Basemap.OpenStreetMaps, name: 'Open Street Maps' },
        {
          id: Basemap.Basemap.GoogleMaps,
          name: 'Google Maps',
          apiKey: { dev: '', stg: '', pro: '' },
        },
      ],
    },
  },
  roles: [
    {
      token_property: 'groups',
      value: '8fa8d202-2189-4aeb-8dcb-3a052cc2f8ae',
      role: Auth.Role.Admin,
    },
    {
      token_property: 'groups',
      value: '02d02132-a1e6-4f25-ad6c-37ea9606c4b2',
      role: Auth.Role.User,
    },
    {
      token_property: 'groups',
      value: '9ceb9181-95e0-4971-a52f-94fee925386e',
      role: Auth.Role.PowerUser,
    },
  ],
  bq_project: {
    dev: 'carto-ps-radarview-c3-eu-dev',
    staging: 'carto-ps-radarview-c3-eu-stg',
    prod: 'carto-dw-ac-ustiapu9',
  },
  carto_events_url: 'https://workspace-gcp-europe-west1.app.carto.com/events',
  base_url: 'https://gcp-europe-west1.api.carto.com',
  auth0_user_key: 'http://app.carto.com/email',
  auth0_account_id_key: 'http://app.carto.com/account_id',
  connection: 'radarview_eu',
  analytics_toolbox_base_url: 'carto-un-eu',
  languages: {
    enable: true,
    options: [
      { id: 'en', name: 'ENGLISH', locale: 'en-gb' },
      { id: 'es', name: 'SPANISH' },
      { id: 'pl', name: 'POLISH' },
      { id: 'nl', name: 'NETHERLANDS' },
    ],
  },
  countries: [
    {
      id: 1,
      id_legacy: 194,
      carto_me_value: '5c1e2eb4-de69-44db-a4de-0971426f7b11',
      name: 'Spain',
      iso2: 'ES',
      iso3: 'SPA',
      meta_region: [
        { type: 'ad_region', name: 'Provincia' },
        { type: 'lau', name: 'Municipios' },
        { type: 'city', name: 'Ciudad' },
        { type: 'nuts1', name: 'Agrupación de comunidades autónomas' },
        { type: 'nuts2', name: 'Comunidades y ciudades autónomas' },
        { type: 'nuts3', name: 'Provincias' },
        { type: 'zip_code', name: 'Códigos postales' },
      ],
      meta_assets: [
        { type: 'l1panelclass', name: 'L1: Panel Class' },
        { type: 'l2product', name: 'L2: Producto' },
        { type: 'l2_product', name: 'L2: Producto' },
        { type: 'l3subproduct', name: 'L3: Clasificación' },
        { type: 'l4subproduct', name: 'L4: Formato' },
        { type: 'l5subproduct', name: 'L5: Sub-producto' },
        { type: 'l6subproduct', name: 'L6: Tipo de mueble' },
        { type: 'l7subproduct', name: 'L7: Sub-producto1' },
        { type: 'l8subproduct', name: 'L8: Tipo' },
        { type: 'l9subproduct', name: 'L9: Subtipo' },
        { type: 'external_panel_id', name: 'Id Cara Geomex' },
        { type: 'ad_region', name: 'Provincia' },
        { type: 'city', name: 'Ciudad' },
      ],
    },
    {
      id: 2,
      id_legacy: 179,
      carto_me_value: 'a20f8359-154d-4872-9a52-617338f790f5',
      name: 'United Kingdom',
      iso2: 'GB',
      iso3: 'GBR',
      meta_region: [
        { type: 'ad_region', name: 'Conurbation' },
        { type: 'lau', name: 'District' },
        { type: 'nuts1', name: 'Region' },
        { type: 'nuts2', name: 'County' },
        { type: 'nuts3', name: 'Local Authority' },
        { type: 'city', name: 'City' },
      ],
      meta_assets: [
        { type: 'l1panelclass', name: 'L1: Environment' },
        { type: 'l2product', name: 'L2: Environment sub-type' },
        { type: 'l2_product', name: 'L2: Environment sub-type' },
        { type: 'l3subproduct', name: 'L3: Frame Class' },
        { type: 'l4subproduct', name: 'L4: Frame Code' },
        { type: 'l5subproduct', name: 'L5: Sub-product' },
        { type: 'l6subproduct', name: 'L6: Sub-product' },
        { type: 'l7subproduct', name: 'L7: Sub-product' },
        { type: 'l8subproduct', name: 'L8: Sub-product' },
        { type: 'l9subproduct', name: 'L9: Sub-product' },
        { type: 'external_panel_id', name: 'Panel ID' },
        { type: 'ad_region', name: 'Conurbation' },
        { type: 'city', name: 'City' },
      ],
    },
    {
      id: 3,
      id_legacy: 198,
      carto_me_value: '297fb4a4-831e-4c64-bc64-ec2c786aa3df',
      name: 'Sweden',
      iso2: 'SE',
      iso3: 'SWE',
      meta_region: [
        { type: 'ad_region', name: 'Agglomeration' },
        { type: 'lau', name: 'Kommuner' },
        { type: 'nuts1', name: 'Grupper av riksområden' },
        { type: 'nuts2', name: 'Riksområden' },
        { type: 'nuts3', name: 'Län' },
        { type: 'city', name: 'Municipality' },
      ],
      meta_assets: [
        { type: 'l1panelclass', name: 'L1: Environment' },
        { type: 'l2product', name: 'L2: Environment sub-type' },
        { type: 'l2_product', name: 'L2: Environment sub-type' },
        { type: 'l3subproduct', name: 'L3: Package Group' },
        { type: 'l4subproduct', name: 'L4: Package Assigned' },
        { type: 'l5subproduct', name: 'L5: Format' },
        { type: 'l6subproduct', name: 'L6: Installation Type' },
        { type: 'l7subproduct', name: 'L7: Plan No' },
        { type: 'l8subproduct', name: 'L8: Type' },
        { type: 'l9subproduct', name: 'L9: Quality' },
        { type: 'external_panel_id', name: 'IMS Face ID' },
        { type: 'ad_region', name: 'Agglomeration' },
        { type: 'city', name: 'Municipality' },
      ],
    },
    {
      id: 4,
      id_legacy: 37,
      carto_me_value: '71f59f7b-c409-42e8-8eab-cee822e95d07',
      name: 'Denmark',
      iso2: 'DK',
      iso3: 'DNK',
      meta_region: [
        { type: 'lau', name: 'Kommuner' },
        { type: 'nuts2', name: 'Regioner' },
        { type: 'nuts3', name: 'Landsdele' },
        { type: 'ad_region', name: 'City' },
        { type: 'city', name: 'Municipality' },
      ],
      meta_assets: [
        { type: 'l1panelclass', name: 'L1: Environment' },
        { type: 'l2product', name: 'L2: Environment sub-type' },
        { type: 'l2_product', name: 'L2: Environment sub-type' },
        { type: 'l3subproduct', name: 'L3: Package Group' },
        { type: 'l4subproduct', name: 'L4: Package Assigned' },
        { type: 'l5subproduct', name: 'L5: Format' },
        { type: 'l6subproduct', name: 'L6: Installation Type' },
        { type: 'l7subproduct', name: 'L7: Plan No' },
        { type: 'l8subproduct', name: 'L8: Type' },
        { type: 'l9subproduct', name: 'L9: Quality' },
        { type: 'external_panel_id', name: 'External Key' },
        { type: 'ad_region', name: 'City' },
        { type: 'city', name: 'Municipality' },
      ],
    },
    {
      id: 6,
      id_legacy: 109,
      carto_me_value: 'c0aff462-940a-4be4-8e1c-dfe78ed2dfae',
      name: 'Belgium',
      iso2: 'BE',
      iso3: 'BEL',
      meta_region: [
        { type: 'lau', name: 'Gemeenten' },
        { type: 'nuts1', name: 'Gewesten' },
        { type: 'nuts2', name: 'Provincies' },
        { type: 'nuts3', name: 'Arrondisse-menten' },
        { type: 'ad_region', name: 'Conurbation' },
        { type: 'city', name: 'City' },
      ],
      meta_assets: [
        { type: 'l1panelclass', name: 'L1: Environment' },
        { type: 'l2product', name: 'L2: Environment sub-type' },
        { type: 'l2_product', name: 'L2: Environment sub-type' },
        { type: 'l3subproduct', name: 'L3: Frame Class' },
        { type: 'l4subproduct', name: 'L4: Frame Code' },
        { type: 'l5subproduct', name: 'L5: Sub-product' },
        { type: 'l6subproduct', name: 'L6: Sub-product' },
        { type: 'l7subproduct', name: 'L7: Sub-product' },
        { type: 'l8subproduct', name: 'L8: Sub-product' },
        { type: 'l9subproduct', name: 'L9: Sub-product' },
        { type: 'external_panel_id', name: 'Panel ID' },
        { type: 'ad_region', name: 'Conurbation' },
        { type: 'city', name: 'City' },
      ],
    },
    {
      id: 7,
      id_legacy: 199,
      carto_me_value: '63bd9b5e-139e-46e8-b824-fd4a9a4284f1',
      name: 'Poland',
      iso2: 'PL',
      iso3: 'POL',
      meta_region: [
        { type: 'lau', name: 'Gminy' },
        { type: 'nuts1', name: 'Makroregiony' },
        { type: 'nuts2', name: 'Regiony' },
        { type: 'nuts3', name: 'Podregiony' },
        { type: 'ad_region', name: 'Region' },
        { type: 'city', name: 'Miasto' },
      ],
      meta_assets: [
        { type: 'l1panelclass', name: 'L1: Środowisko' },
        { type: 'l2product', name: 'L2: Podtyp Środowiska' },
        { type: 'l2_product', name: 'L2: Podtyp Środowiska' },
        { type: 'l3subproduct', name: 'L3: Typ Konstrukcji' },
        { type: 'l4subproduct', name: 'L4: Format (Rozdzielczość)' },
        { type: 'l5subproduct', name: 'L5: ', hide: true },
        { type: 'l6subproduct', name: 'L6: ', hide: true },
        { type: 'l7subproduct', name: 'L7: Podprodukt' },
        { type: 'l8subproduct', name: 'L8: Podprodukt' },
        { type: 'l9subproduct', name: 'L9: Format' },
        { type: 'external_panel_id', name: 'External Panel ID' },
        { type: 'ad_region', name: 'Region' },
        { type: 'city', name: 'Miasto' },
      ],
    },
    {
      id: 8,
      id_legacy: 213,
      carto_me_value: '826db3e7-982c-4631-81bc-cacc13115431',
      name: 'Finland',
      iso2: 'FI',
      iso3: 'FIN',
      meta_region: [
        { type: 'lau', name: 'Kunnat' },
        { type: 'nuts1', name: 'Manner-Suomi, Ahvenananmaa' },
        { type: 'nuts2', name: 'Suuralueet' },
        { type: 'nuts3', name: 'Maakunnat' },
        { type: 'ad_region', name: 'County' },
        { type: 'city', name: 'City' },
      ],
      meta_assets: [
        { type: 'l1panelclass', name: 'L1', hide: true },
        { type: 'l2product', name: 'L2: Environment level 2' },
        { type: 'l2_product', name: 'L2: Environment level 2' },
        { type: 'l3subproduct', name: 'L3: Panel classification' },
        { type: 'l4subproduct', name: 'L4: Format' },
        { type: 'l5subproduct', name: 'L5', hide: true },
        { type: 'l6subproduct', name: 'L6', hide: true },
        { type: 'l7subproduct', name: 'L7', hide: true },
        { type: 'l8subproduct', name: 'L8', hide: true },
        { type: 'l9subproduct', name: 'L9: Panel Group' },
        { type: 'external_panel_id', name: 'Panel Alt ID' },
        { type: 'ad_region', name: 'County' },
        { type: 'city', name: 'City' },
      ],
    },
    {
      id: 9,
      id_legacy: 214,
      carto_me_value: '8c80e8af-2311-4a70-8752-f547142c3cc4',
      name: 'Netherlands',
      iso2: 'NL',
      iso3: 'NLD',
      meta_region: [
        { type: 'lau', name: 'Gemeenten' },
        { type: 'nuts1', name: 'Landsdelen' },
        { type: 'nuts2', name: 'Provincies' },
        { type: 'ad_region', name: 'Regio' },
        { type: 'city', name: 'Stad' },
      ],
      meta_assets: [
        { type: 'l1panelclass', name: 'L1: Omgeving' },
        { type: 'l2product', name: 'L2: Segment' },
        { type: 'l2_product', name: 'L2: Segment' },
        { type: 'l3subproduct', name: 'L3: Product Categorieen' },
        { type: 'l4subproduct', name: 'L4: Pakketten' },
        { type: 'l5subproduct', name: 'L5: Formaat' },
        { type: 'l6subproduct', name: 'L6: Furniture Type' },
        { type: 'l7subproduct', name: 'L7: Plan No' },
        { type: 'l8subproduct', name: 'L8: Type' },
        { type: 'l9subproduct', name: 'L9' },
        { type: 'external_panel_id', name: 'External Panel ID' },
        { type: 'ad_region', name: 'Regio' },
        { type: 'city', name: 'Stad' },
      ],
    },
    {
      id: 10,
      id_legacy: 238,
      carto_me_value: 'fa0d16bf-18a4-4f73-b49a-a5bb2df31f1a',
      name: 'Ireland',
      iso2: 'IE',
      iso3: 'IRL',
      meta_region: [
        { type: 'lau', name: 'Local Electoral Areas' },
        { type: 'nuts1', name: 'Regions' },
        { type: 'nuts2', name: 'Regional Authority Regions' },
        { type: 'ad_region', name: 'Conurbation' },
        { type: 'city', name: 'City' },
      ],
      meta_assets: [
        { type: 'l1panelclass', name: 'L1: Environment' },
        { type: 'l2product', name: 'L2: Environment sub-type' },
        { type: 'l2_product', name: 'L2: Environment sub-type' },
        { type: 'l3subproduct', name: 'L3: Frame Class' },
        { type: 'l4subproduct', name: 'L4: Frame Code' },
        { type: 'l5subproduct', name: 'L5: Sub-product' },
        { type: 'l6subproduct', name: 'L6: Sub-product' },
        { type: 'l7subproduct', name: 'L7: Sub-product' },
        { type: 'l8subproduct', name: 'L8: Sub-product' },
        { type: 'l9subproduct', name: 'L9: Sub-product' },
        { type: 'external_panel_id', name: 'Panel ID' },
        { type: 'ad_region', name: 'Conurbation' },
        { type: 'city', name: 'City' },
      ],
    },
    {
      id: 11,
      id_legacy: 239,
      carto_me_value: '0af42fae-af4b-4741-85f8-6d72e0674720',
      name: 'Norway',
      iso2: 'NO',
      iso3: 'NOR',
      meta_region: [
        { type: 'lau', name: 'Kommuner' },
        { type: 'nuts1', name: 'Landsdeler' },
        { type: 'nuts2', name: 'Fylker' },
        { type: 'ad_region', name: 'Agglomeration' },
        { type: 'city', name: 'Municipality' },
      ],
      meta_assets: [
        { type: 'l1panelclass', name: 'L1: Environment' },
        { type: 'l2product', name: 'L2: Environment sub-type' },
        { type: 'l2_product', name: 'L2: Environment sub-type' },
        { type: 'l3subproduct', name: 'L3: Package Group' },
        { type: 'l4subproduct', name: 'L4: Package Assigned' },
        { type: 'l5subproduct', name: 'L5: Format' },
        { type: 'l6subproduct', name: 'L6: Installation Type' },
        { type: 'l7subproduct', name: 'L7: Plan No' },
        { type: 'l8subproduct', name: 'L8: Type' },
        { type: 'l9subproduct', name: 'L9: Quality' },
        { type: 'external_panel_id', name: 'External Key' },
        { type: 'ad_region', name: 'Agglomeration' },
        { type: 'city', name: 'Municipality' },
      ],
    },
    {
      id: 13,
      id_legacy: 20534550,
      carto_me_value: '656b0870-8dfe-4d8e-b7b6-38cf5b4d88ca',
      name: 'Northern Ireland',
      iso2: 'GB-NIR',
      iso3: 'GB-NIR',
      geocoder: 'GB',
      meta_region: [
        { type: 'ad_region', name: 'Conurbation' },
        { type: 'lau', name: 'District' },
        { type: 'nuts1', name: 'Region' },
        { type: 'nuts2', name: 'County' },
        { type: 'nuts3', name: 'Local Authority' },
        { type: 'city', name: 'City' },
      ],
      meta_assets: [
        { type: 'l1panelclass', name: 'L1: Environment' },
        { type: 'l2product', name: 'L2: Environment sub-type' },
        { type: 'l2_product', name: 'L2: Environment sub-type' },
        { type: 'l3subproduct', name: 'L3: Frame Class' },
        { type: 'l4subproduct', name: 'L4: Frame Code' },
        { type: 'l5subproduct', name: 'L5: Sub-product' },
        { type: 'l6subproduct', name: 'L6: Sub-product' },
        { type: 'l7subproduct', name: 'L7: Sub-product' },
        { type: 'l8subproduct', name: 'L8: Sub-product' },
        { type: 'l9subproduct', name: 'L9: Sub-product' },
        { type: 'external_panel_id', name: 'Panel ID' },
        { type: 'ad_region', name: 'Conurbation' },
        { type: 'city', name: 'City' },
      ],
    },
  ],
  local_storage_keys: {
    lang: 'CCI_lang',
    auth: 'CCI_auth',
    firebase_token: 'CCI_firebaseToken',
  },
  units: {
    distance: 'meters',
    currency: 'EUR',
    currency_symbol: '€',
  },
  data_model: {
    regions: {
      table_name: 'regions',
      regions_hierarchy: {
        region_1: {
          id: 1,
          name: 'country',
          order: 1,
          countries: [],
          geom: true,
        },
        region_2: { id: 2, name: 'nuts1', order: 4, countries: [], geom: true },
        region_3: { id: 3, name: 'nuts2', order: 5, countries: [], geom: true },
        region_4: { id: 4, name: 'nuts3', order: 6, countries: [], geom: true },
        region_5: { id: 5, name: 'lau', order: 7, countries: [], geom: true },
        region_6: {
          id: 6,
          name: 'regionccf',
          order: 8,
          countries: [5],
          geom: true,
        },
        region_7: {
          id: 7,
          name: 'agglomerationccf',
          order: 9,
          countries: [5],
          geom: true,
        },
        region_8: {
          id: 8,
          name: 'unite_urban',
          order: 10,
          countries: [5],
          geom: true,
        },
        region_9: { id: 9, name: 'city', order: 2, countries: [], geom: false },
        region_10: {
          id: 10,
          name: 'zip_code',
          order: 11,
          countries: [],
          geom: false,
        },
        region_11: {
          id: 11,
          name: 'ad_region',
          order: 3,
          countries: [],
          geom: false,
        },
        region_15: {
          id: 15,
          name: 'file',
          order: 15,
          countries: [],
          geom: true,
        },
        region_16: {
          id: 16,
          name: 'custom_geoboundary_dataset',
          order: 16,
          countries: [],
          geom: true,
        },
        region_17: {
          id: 17,
          name: 'custom_geoboundary_dataset_public',
          order: 17,
          countries: [],
          geom: true,
        },
        region_18: {
          id: 18,
          name: 'file_custom_geoboundary_dataset',
          order: 18,
          countries: [],
          geom: true,
        },
      },
      partitioned_column: 'dma',
      columns: [
        {
          id: 'id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          autocomplete: true,
          display: false,
        },
        {
          id: 'name',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          autocomplete: true,
          display: false,
        },
        {
          id: 'normalized_name',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          autocomplete: true,
          display: false,
        },
        {
          id: 'type',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          autocomplete: true,
          display: false,
        },
        {
          id: 'geom',
          type: Metadata.DataModelFieldTypes.Geography,
          visible: false,
          filterable: true,
          autocomplete: false,
          display: false,
        },
        {
          id: 'country_id',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          autocomplete: true,
          display: false,
        },
        {
          id: 'iso2',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          autocomplete: true,
          display: false,
        },
        {
          id: 'iso3',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          autocomplete: true,
          display: false,
        },
        {
          id: 'parent',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          autocomplete: false,
          display: false,
        },
      ],
    },
    assets: {
      table_name: 'assets_regions',
      chunk_size: 21000,
      cache: {
        dbName: 'Radarview_EU',
        store: 'assetsStore',
        key: 'assets',
        key_last_update: 'lastUpdate',
        version: 1,
      },
      cluster_icon: 'cluster-20',
      columns: [
        {
          id: 'id',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
          background: true,
        },
        {
          id: 'geom',
          type: Metadata.DataModelFieldTypes.Geography,
          visible: false,
          filterable: true,
          display: false,
          startup: false,
        },
        {
          id: 'name',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: true,
          startup: false,
          background: true,
        },
        {
          id: 'internal_panel_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'external_panel_id',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'size',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: true,
        },
        {
          id: 'impressions',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'is_digital',
          type: Metadata.DataModelFieldTypes.Boolean,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'pictureurl',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: true,
        },
        {
          id: 'l1panelclass_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'l1panelclass',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: true,
          startup: false,
          background: true,
        },
        {
          id: 'l2product_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'l2_product',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: true,
          startup: false,
          background: true,
        },
        {
          id: 'l3subproduct_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'l3subproduct',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: true,
          startup: false,
          background: true,
        },
        {
          id: 'l4_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'l4',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: true,
          startup: false,
          background: true,
        },
        {
          id: 'l5_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'l5',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: true,
          startup: false,
          background: true,
        },
        {
          id: 'l6_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'l6',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: true,
          startup: false,
          background: true,
        },
        {
          id: 'l7_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'l7',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: true,
          startup: false,
          background: true,
        },
        {
          id: 'l8_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'l8',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: true,
          startup: false,
          background: true,
        },
        {
          id: 'l9_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
          startup: true,
        },
        {
          id: 'l9',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: true,
          background: true,
        },
        {
          id: 'nuts1_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'nuts1_name',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: true,
        },
        {
          id: 'nuts2_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'nuts2_name',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: true,
        },
        {
          id: 'nuts3_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
          startup: true,
        },
        {
          id: 'nuts3_name',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: true,
        },
        {
          id: 'country_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'country_name',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: true,
        },
        {
          id: 'lau_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'lau_name',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: true,
        },
        {
          id: 'city_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
          startup: true,
        },
        {
          id: 'city_name',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: true,
          background: true,
        },
        {
          id: 'zip_code_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'zip_code_name',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'ad_region_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'ad_region_name',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: true,
          background: true,
        },
        {
          id: 'regionccf_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'regionccf_name',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: true,
        },
        {
          id: 'agglomerationccf_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'agglomerationccf_name',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: true,
        },
        {
          id: 'unite_urban_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
        {
          id: 'unite_urban_name',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: true,
        },
        {
          id: 'latitude',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: false,
          display: true,
          startup: true,
        },
        {
          id: 'longitude',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: false,
          display: true,
          startup: true,
        },
        {
          id: 'address',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: true,
          background: true,
        },
        {
          id: 'business_unit_id',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: false,
          background: true,
        },
        {
          id: 'address2',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: false,
          display: true,
        },
        {
          id: 'angle_to_map_north',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: true,
          startup: true,
        },
      ],
    },
    custom_pois_address_tmp: {
      table_name: 'custom_pois_address_tmp',
      columns: [
        {
          id: 'address',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'name',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'carto_geocode_metadata',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: false,
        },
      ],
    },
    custom_pois: {
      table_name: 'custom_pois',
      columns: [
        {
          id: 'name',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'pois',
          type: Metadata.DataModelFieldTypes.Array,
          visible: false,
          filterable: false,
          display: false,
        },
        {
          id: 'ishared',
          type: Metadata.DataModelFieldTypes.Boolean,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'user_email',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'category',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'subcategory',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: false,
        },
      ],
    },
    pois: {
      table_name: 'pois',
      autocomplete: 'pois',
      autocomplete_locations: 'pois_autocomplete',
      tileset: 'pois_tileset',
      columns: [
        {
          id: 'id',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'location_name',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'top_category',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'sub_category',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'naics_code',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'geom',
          type: Metadata.DataModelFieldTypes.Geography,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'street_address',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'category_tags',
          type: Metadata.DataModelFieldTypes.Array,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'state_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'state_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'dmas_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'congressional_distric_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'cbsa_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'county_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'city_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'zip_code_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'category_tags',
          type: Metadata.DataModelFieldTypes.String,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'country_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
      ],
    },
    audiences: {
      table_name: 'audiences',
      columns: [
        {
          id: 'id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'year',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'month',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'panel',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'market',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'index',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'asset_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'clazz_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'county_id',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'month_unique_counts',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
        {
          id: 'month_contact_counts',
          type: Metadata.DataModelFieldTypes.Number,
          visible: false,
          filterable: true,
          display: false,
        },
      ],
    },
    audiences_classes: {
      table_name: 'audiences_classes',
      columns: [
        {
          id: 'year_month_or_week',
          type: Metadata.DataModelFieldTypes.Date,
          visible: true,
          filterable: true,
          display: false,
        },
        {
          id: 'country_iso3',
          type: Metadata.DataModelFieldTypes.String,
          visible: true,
          filterable: true,
          display: false,
        },
        {
          id: 'uid',
          type: Metadata.DataModelFieldTypes.String,
          visible: true,
          filterable: true,
          display: false,
        },
        {
          id: 'class_group',
          type: Metadata.DataModelFieldTypes.String,
          visible: true,
          filterable: true,
          display: false,
        },
        {
          id: 'audience',
          type: Metadata.DataModelFieldTypes.String,
          visible: true,
          filterable: true,
          display: false,
        },
        {
          id: 'attr1',
          type: Metadata.DataModelFieldTypes.String,
          visible: true,
          filterable: true,
          display: false,
        },
        {
          id: 'attr2',
          type: Metadata.DataModelFieldTypes.String,
          visible: true,
          filterable: true,
          display: false,
        },
        {
          id: 'attr3',
          type: Metadata.DataModelFieldTypes.String,
          visible: true,
          filterable: true,
          display: false,
        },
        {
          id: 'attr4',
          type: Metadata.DataModelFieldTypes.String,
          visible: true,
          filterable: true,
          display: false,
        },
        {
          id: 'attr5',
          type: Metadata.DataModelFieldTypes.String,
          visible: true,
          filterable: true,
          display: false,
        },
        {
          id: 'attr6',
          type: Metadata.DataModelFieldTypes.String,
          visible: true,
          filterable: true,
          display: false,
        },
        {
          id: 'name',
          type: Metadata.DataModelFieldTypes.String,
          visible: true,
          filterable: true,
          display: false,
        },
        {
          id: 'type',
          type: Metadata.DataModelFieldTypes.Number,
          visible: true,
          filterable: true,
          display: false,
        },
      ],
    },
    panelcounts: {
      table_name: 'panel_counts',
      columns: [],
    },
    pois_isochrones: {
      table_name: 'pois_isochrones',
      columns: [],
    },
    shared_plans_assets: {
      table_name: 'shared_plan_results',
      columns: [],
    },
    custom_geoboundaries: {
      table_name: 'custom_geoboundaries',
      columns: [],
    },
    packages: {
      table_name: 'packages',
      columns: [],
    },
    packages_availability: {
      table_name: 'packages_availability',
      columns: [],
    },
    assets_optimize_distribution: {
      table_name: 'assets_optimize_distribution',
      columns: [],
    },
  },
  features: {
    permission_resolver: Auth.PermissionResolver.EU,
    comments: {
      enable: false,
      attachments: {
        enable: false,
        max_size_mb: 20,
      },
      show_toolbar_summary_plans_comments: false,
      realtime_reaction_in_seconds: 5,
    },
    custom_geoboundaries: {
      enable: true,
      upload_file: true,
      upload_file_max_size_mb: 10,
      save_group_from_plan: true,
    },
    packages: {
      enable: false,
      expiration: {
        booking_confirmation: 48 * 60 * 60 * 1000,
        cancellation_time_limit: 48 * 60 * 60 * 1000,
      },
      availability_range: [
        {
          value: 90,
          text: 'HIGH_RANGE',
          color: '#76cc85',
          description: 'Campaign can most likely be delivered',
        },
        {
          value: 40,
          text: 'MEDIUM_RANGE',
          color: '#f0a65e',
          description:
            'Campaign can most likely be delivered with small adjustments',
        },
        {
          value: 0,
          text: 'LOW_RANGE',
          color: '#fb837d',
          description: 'High demand',
        },
      ],
      states_style: {
        [Package.State.Draft]: { background: '#c9cbd1', foreground: '#383d4c' },
        [Package.State.BookingRequest]: {
          background: '#fbcf7d',
          foreground: '#383d4c',
        },
        [Package.State.BookingConfirmedByAdmin]: {
          background: '#fbcf7d',
          foreground: '#383d4c',
        },
        [Package.State.BookingConfirmedByAgency]: {
          background: '#76cc85',
          foreground: '#ffffff',
        },
        [Package.State.ReBooking]: {
          background: '#fbcf7d',
          foreground: '#383d4c',
        },
        [Package.State.Expired]: {
          background: '#fb837d',
          foreground: '#ffffff',
        },
        [Package.State.Booked]: {
          background: '#296f34',
          foreground: '#ffffff',
        },
        [Package.State.Cancelled]: {
          background: '#cb433c',
          foreground: '#ffffff',
        },
        [Package.State.Rejected]: {
          background: '#81413e',
          foreground: '#ffffff',
        },
      },
      dashboard_columns: {
        locale_keys: [
          'TITLE',
          'DATE_RANGE',
          'STATUS',
          'PANELS',
          'PRICE',
          'AVAILABILITY',
          'IMPRESSIONS',
          'USERS',
          'LAST_UPDATE',
        ],
        model_accessors: [
          'title',
          'date_range',
          'state',
          'panels',
          'price',
          'availability',
          'impressions',
          'users',
          'last_update',
        ],
      },
    },
    plan: {
      realtime: {
        enable: false,
        reaction_in_seconds: 5,
      },
      dashboard_columns: {
        locale_keys: [
          'TITLE',
          'PANELS',
          'IMPRESSIONS',
          'USER',
          'DATE_MODIFIED',
        ],
        model_accessors: [
          'name',
          'count',
          'impressions',
          'created',
          'modified',
        ],
      },
      save_brand: false,
      save_client: false,
      enable_quick_access_to_visibility: false,
    },
    assets: {
      exclude: true,
    },
    widgets: {
      show_button_tooltip_as_copies: false,
    },
    proximity: {
      max_assets_per_poi: 25,
      max_zip_codes_per_file: 1000,
      multicolor: true,
      ramp_color: [
        '#7F3C8D',
        '#11A579',
        '#3969AC',
        '#F2B701',
        '#E73F74',
        '#80BA5A',
        '#E68310',
        '#008695',
        '#CF1C90',
        '#F97B72',
        '#4B4B8F',
      ],
      others_color: '#666666',
      default_color: '#b08527',
    },
    map: {
      layers_precedence: AllLayersPrecedence,
      asset_details_over_map: true,
      fixed_cluster_size: -1,
      zoom_hide_cluster: 10,
      max_bbox: [-180, -90, 180, 90],
      view_state: {
        longitude: 9.272461,
        latitude: 53.1,
        zoom: 2,
        bearing: 0,
        pitch: 0,
      },
      legend: {
        colors: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        ],
        enable_product_selection: true,
        default_product_selection: 'l2_product_id',
        exclude_assets: false,
        facing_direction: false,
        angle_to_map_north: true,
        show_geoboundaries_layer: true,
        show_heatmap_layer: true,
        show_pois_layer: true,
        show_product_selection: true,
        title: 'LAYERS',
        show_buffers_visibility_control: true,
        default_buffers_visibility: true,
        show_assets_ids_layer: true,
      },
      highlightSelectedMarker: false,
      screenshot_btn: true,
    },
    audit: {
      enable: false,
    },
    dashboard: {
      show_others_groups_tab: true,
      show_public_plans_by_country_and_user: true,
    },
    share: {
      enable: {
        dev: true,
        staging: true,
        prod: true,
      },
      retrieve_in_blocks_of: 20000,
      expiracyOffset: 60 * 60 * 24 * 365 * 1000,
      password: true,
      export_enabled: true,
      password_is_required: false,
      expiracy: {
        enabled: false,
        defaultExpirationInDays: 30,
      },
      feedback_email: '',
      hide_unused_filter_widgets: false,
    },
    new_plan: {
      enable: true,
    },
    save_plan: {
      enable_save_as: false,
      enable: true,
    },
    export: {
      enable: true,
      include_total_assets_column: true,
      add_tab_xlsx_pois: true,
      add_tab_xlsx_proximity: true,
      fileColumnTitles: [
        Export.FilePOIColumns.Id,
        Export.FilePOIColumns.Name,
        Export.FilePOIColumns.Distance,
        Export.FilePOIColumns.Address,
        Export.FilePOIColumns.ZipCode,
        Export.FilePOIColumns.City,
      ],
      columnsPOIs: [
        'p.geoId as id',
        'p.location_name as name',
        'p.geom',
        'p.street_address address',
        'p.postal_code zip_code',
        'p.city as city',
      ],
      columnsAllPOIs: [
        'COALESCE(id, " ") as id',
        'name',
        'geom',
        'COALESCE(address, " ") as address',
        'COALESCE(CAST(zip_code AS String), " ") as zip_code',
        'COALESCE(city, " ") as city',
      ],
      columnsCustomPOIs: [
        'id',
        'name',
        'geom',
        'COALESCE(address, " ") as address',
        'COALESCE(zip_code, " ") as zip_code',
        'COALESCE(city, " ") as city',
      ],
      fields_mapping: [
        {
          model: 'assets',
          modelField: 'id',
          exportField: 'radarview_id',
        },
        {
          model: 'assets',
          modelField: 'internal_panel_id',
          exportField: 'internal_panel_id',
        },
        {
          model: 'assets',
          modelField: 'external_panel_id',
          exportField: 'external_panel_id',
          avoidApplyingNumberFormat: true,
        },
        {
          model: 'assets',
          modelField: 'latitude',
          exportField: 'latitude',
        },
        { model: 'assets', modelField: 'longitude', exportField: 'longitude' },
        {
          model: 'assets',
          modelField: 'l1panelclass',
          exportField: 'l1panelclass',
        },
        {
          model: 'assets',
          modelField: 'l2_product',
          exportField: 'l2product',
        },
        {
          model: 'assets',
          modelField: 'l3subproduct',
          exportField: 'l3subproduct',
        },
        {
          model: 'assets',
          modelField: 'l4',
          exportField: 'l4subproduct',
        },
        {
          model: 'assets',
          modelField: 'l5',
          exportField: 'l5subproduct',
        },
        {
          model: 'assets',
          modelField: 'l6',
          exportField: 'l6subproduct',
        },
        {
          model: 'assets',
          modelField: 'l7',
          exportField: 'l7subproduct',
        },
        {
          model: 'assets',
          modelField: 'l8',
          exportField: 'l8subproduct',
        },
        {
          model: 'assets',
          modelField: 'l9',
          exportField: 'l9subproduct',
        },
        {
          model: 'assets',
          modelField: 'is_digital',
          exportField: 'is_digital',
          formatFunction: (data: boolean) => {
            return data ? 'Yes' : 'No'
          },
        },
        {
          model: 'assets',
          modelField: 'business_unit_id',
          exportField: 'business_unit_id',
        },
        {
          model: 'assets',
          modelField: 'address',
          exportField: 'address',
        },
        {
          model: 'assets',
          modelField: 'zip_code_name',
          exportField: 'postal_code',
        },
        {
          model: 'assets',
          modelField: 'city_name',
          exportField: 'city',
        },
        {
          model: 'assets',
          modelField: 'ad_region_name',
          exportField: 'ad_region',
        },
        {
          model: 'assets',
          modelField: 'impressions',
          exportField: 'impressions',
          formatFunction: (data: number) => {
            return data ? Math.round(data) : 0
          },
          avoidInSharedPlan: true,
        },
      ],
    },
    insights: {
      enable: true,
      detail: {
        id_field: 'internal_panel_id',
        second_id_field: 'external_panel_id',
        show_image: true,
        show_advanced_address: true,
        show_media_type: true,
        show_audience: false,
      },
      tabs: {
        impressions: true,
        demographics: true,
        top_indexing: true,
        table_view: true,
      },
      features: {
        demographics: {
          simpliest_chart: 'pie',
          pre_filter_audiences_classes: false,
          sort_by_values: false,
          identify_gender_charts: ['gender'],
          format: {
            index: (value: number) => {
              return value
            },
            panel: (value: number) => {
              return value
            },
            market: (value: number) => {
              return value
            },
          },
        },
        table_view: {
          name: true,
          market: true,
          panel: true,
          index: true,
        },
      },
    },
    manage_pois: {
      enable: true,
      types: {
        coords: true,
        geocoding: true,
      },
      default_type: Poi.CustomPOIsImportMode.Geocode,
      distinguish_between_user_and_admin: true,
      max_custom_pois_per_file: 1000,
      max_custom_pois_geocoding: 1000,
      max_custom_pois_per_file_admin_user: 6000,
      max_custom_pois_geocoding_admin_user: 6000,
      xlsx_template_geocode: {
        template: 'eu_template_custom_pois.xlsx',
        mandatory_fields_in_template: [
          'name',
          'address',
          'city',
          'postalcode',
          'country',
        ],
        mandatory_fields_with_data: ['country'],
        at_least_one_field_with_data_within: ['address', 'city', 'postalcode'],
      },
      xlsx_template_coords: {
        template: 'eu_template_custom_pois.xlsx',
        mandatory_fields_in_template: ['name', 'lat', 'lng'],
        mandatory_fields_with_data: ['lat', 'lng'],
        optional_fields: ['name', 'address', 'city', 'postalcode', 'country'],
      },
      xlsx_header_download: [
        'name',
        'address',
        'city',
        'postalcode',
        'country',
        'lat',
        'lng',
      ],
      custom_pois_expiration: {
        enable: true,
        days: 181,
      },
    },
    popup: {
      title_i18n: 'internalpanelidpopup',
      title_id_field: 'internal_panel_id',
      subtitle_id_field: 'external_panel_id',
      show_picture: true,
      show_address: true,
      show_coordinates: true,
      show_angle_to_map_north: true,
      show_facing_direction: false,
      show_streetview: true,
      show_panel_name: false,
      show_type: true,
      show_impressions: true,
      show_audiences: false,
      show_exclude: true,
      show_details: true,
      shared_plan_show_details: false,
      show_isoline: false,
    },
    popup_minimal: {
      title_i18n: 'internalpanelidpopup',
      title_id_field: 'internal_panel_id',
      subtitle_id_field: '',
      show_picture: true,
      show_address: true,
      show_coordinates: true,
      show_angle_to_map_north: true,
      show_facing_direction: false,
      show_streetview: true,
      show_panel_name: false,
      show_type: false,
      show_impressions: true,
      show_audiences: false,
      show_exclude: true,
      show_details: false,
      shared_plan_show_details: false,
      show_isoline: false,
    },
    popup_share: {
      title_i18n: 'internalpanelidpopup',
      title_id_field: 'internal_panel_id',
      subtitle_id_field: '',
      show_picture: true,
      show_address: true,
      show_coordinates: true,
      show_angle_to_map_north: true,
      show_facing_direction: false,
      show_streetview: true,
      show_panel_name: false,
      show_type: true,
      show_impressions: true,
      show_audiences: false,
      show_exclude: false,
      show_details: false,
      shared_plan_show_details: false,
      show_isoline: false,
    },
    help: {
      enable: false,
      url: '',
    },
    terms_of_use: {
      enable: false,
      url: '',
    },
    privacy_statement: {
      enable: false,
      url: '',
    },
    multiTabsFilters: {
      enable: true,
      max_tabs: 3,
    },
  },
  plan_filters: {
    widget_countries: {
      enable: true,
      default_selection_iso3: [],
    },
    widget_assets: {
      enable: true,
      enable_only_admin: false,
      xlsx_column_name: 'internal_panel_id',
      example_file: 'eu_template_panel_ids.xlsx',
      upload_by_file: true,
      upload_by_paste: false,
      default_option: 'file',
      upload_inventory_permit_excluded: false,
    },
    widget_geoboundaries: {
      enable: true,
      permit_exclude: true,
      custom_drawing: {
        enable: true,
        polygon: true,
        circle: true,
        route: false,
      },
      sample_files: [
        { name: 'Belgium', link: 'eu_postal_codes_BE.xlsx' },
        { name: 'United Kingdom', link: 'eu_postal_codes_GB.xlsx' },
        { name: 'Spain', link: 'eu_postal_codes_ES.xlsx' },
        { name: 'Sweden', link: 'eu_postal_codes_SE.xlsx' },
        { name: 'Northern Ireland', link: 'eu_postal_codes_GB-NIR.xlsx' },
        { name: 'Netherlands', link: 'eu_postal_codes_NL.xlsx' },
      ],
    },
    widget_audiences: {
      enable: { dev: true, staging: true, prod: true },
      show_delivery_selector: { dev: true, staging: true, prod: true },
      version: Audience.AudienceFeatureVersion.V2,
      delivery_human_representation:
        Metadata.DELIVERY_HUMAN_REPRESENTATION.Week,
      max_AND: 10,
      max_OR: 10,
      by_index: {
        name: 'index',
        enable: false,
        max_value: 1000,
        default_min_value: 100,
        default_max_value: 800,
      },
      by_percentage: {
        name: 'percent',
        enable: true,
        selected_min_value: 90,
        selected_max_value: 100,
      },
    },
    widget_media_type: {
      enable: true,
      version: Media.MediaTypeFeatureVersion.V2,
      clear_action_acts_on_digital_filter: true,
      filter_digital: true,
      types: {
        l1panelclass: 'l1panelclass_id',
        l2product: 'l2product_id',
        l3subproduct: 'l3subproduct_id',
        l4: 'l4_id',
        l5: 'l5_id',
        l6: 'l6_id',
        l7: 'l7_id',
        l8: 'l8_id',
        l9: 'l9_id',
      },
      intelligent_digital_static_filter: true,
    },
    widget_list_assets: {
      enable: true,
      fields: ['internal_panel_id', 'name', 'impressions'],
      sortBy: 'name',
      add_copy_btn: true,
      switch_to_list_view: true,
    },
    widget_optimize_distribution: {
      enable: true,
      disable_production: false,
      types: [
        Metadata.TYPES_OPTIMIZE_DISTRIBUTION.Impressions,
        Metadata.TYPES_OPTIMIZE_DISTRIBUTION.Audience,
      ],
      prioritary_regions: [1, 2, 3, 4, 5, 6, 7, 8],
      excluded_regions: [15, 16, 17, 18],
      max_number_assets: {
        enable: true,
        value: 10,
      },
      min_distance_between_assets: {
        enable: true,
        units: 'm',
        max_distance: 500,
        selected_distance: 7,
        num_elements_direct_query: 10000,
      },
    },
    widget_proximity: {
      enable: true,
      version: Proximity.ProximityFeatureVersion.V2,
      modal: {
        show_brands: true,
        show_custom_pois: true,
        show_tags: true,
        show_locations: true,
        search_by: ['categories', 'brands', 'custom pois', 'tags', 'locations'],
      },
      pois_isochrones_fields: ['geoid', 'country_id', 'geom'],
      types: {
        radius: {
          id: 'RADIUS',
          name: 'RADIUS',
          enable: true,
          units: 'meters',
          min_distance: 0,
          max_distance: 25000,
          double_buffer: true,
          selected_min_distance: 0,
          selected_max_distance: 1000,
          max_assets_per_poi: {
            enable: true,
            max: 25,
          },
        },
        driving: {
          id: 'DRIVING',
          name: 'DRIVING',
          enable: true,
          options: [300, 900, 1800, 3600],
          selected: 300,
        },
        walking: {
          id: 'WALKING',
          name: 'WALKING',
          enable: true,
          options: [300, 900, 1800, 3600],
          selected: 300,
        },
      },
      max_isochrones: 15000,
      show_modal_warning_credits: false,
    },
    widget_packages: {
      enable: false,
      price_range: [2000, 5000],
    },
  },
}
